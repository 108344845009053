import React from 'react';
import SingleExpartContent from '../../../components/SingleExpartContent/SingleExpartContent';

const HomeTwoExpertArea = () => {
   return (
      <>
         <section className="expart__area pt-200 wow fadeInUp" data-wow-delay=".4s">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="expart__wrapper">

                        <div className="expart__nav">
                           <ul className="nav nav-pills justify-content-end" id="expart-tab" role="tablist">
                              <li className="nav-item text-center">
                                 <a className="nav-link active" id="integrations-tab" data-bs-toggle="pill" href="#integrations" role="tab" aria-controls="integrations" aria-selected="true">Integrations</a>
                              </li>
                              <li className="nav-item text-center">
                                 <a className="nav-link" id="help-tab" data-bs-toggle="pill" href="#help" role="tab" aria-controls="help" aria-selected="false">Learning Center</a>
                              </li>

                              <li className="nav-item text-center">
                                 <a className="nav-link" id="game-tab" data-bs-toggle="pill" href="#game" role="tab" aria-controls="game" aria-selected="false">Gamification</a>
                              </li>

                           </ul>
                        </div>



                        <div className="expart__tab">
                           <div className="tab-content" id="expart-Content">
                              <div className="tab-pane fade show active" id="integrations" role="tabpanel" aria-labelledby="integrations-tab">
                                 {/* Tab 1: Integrations */}
                                 <SingleExpartContent 
                                    image="1" 
                                    title={<span>Our experts<br />will guide you</span>} 
                                    description="Connect your tools and centralize the most important information from them. Integrations with AI agents, forms, finances, email, marketing, communication, and more."
                                 /> 
                              </div>
                              <div className="tab-pane fade" id="help" role="tabpanel" aria-labelledby="help-tab">
                                 {/* Tab 2: Help */}
                                 <SingleExpartContent 
                                    image="3" 
                                    title={<span>Learn, implement<br />and grow your business</span>} 
                                    description="Gain insights, implement strategies, and achieve sustainable growth with the support of modern tools and expert guidance."
                                 /> 
                              </div>
                              <div className="tab-pane fade" id="game" role="tabpanel" aria-labelledby="game-tab">
                                 {/* Tab 3: Game */}
                                 <SingleExpartContent 
                                    image="2" 
                                    title={<span>Earn rewards and<br />exchange for bonuses</span>} 
                                    description="Participate in challenges, earn rewards, and redeem them for exclusive bonuses to boost your business performance."
                                 /> 
                              </div>
                           </div>
                        </div>

                        
                           {/* <div className="expart__tab">
                              <div className="tab-content" id="expart-Content">
                                 <div className="tab-pane fade show active" id="integrations" role="tabpanel" aria-labelledby="integrations-tab">
                                    <SingleExpartContent image="1" title={<span>Our experts<br />will guide you</span>} description="Conecta tus herramientas y centraliza la información más importante de ellas. Conexiones con Agentes IA, formularios, finanzas, email, marketing, comunicación..." /> 
                                 </div>
                                 <div className="tab-pane fade" id="help" role="tabpanel" aria-labelledby="help-tab">
                                    <SingleExpartContent image="2" title={<span>Learn, implement<br />and growth your business</span>} description="Conecta tus herramientas y centraliza la información más importante de ellas. Conexiones con Agentes IA, formularios, finanzas, email, marketing, comunicación..." /> 
                                 </div>
                                 <div className="tab-pane fade" id="game" role="tabpanel" aria-labelledby="game-tab">
                                    <SingleExpartContent image="3" title={<span>Earn rewards and<br />exchange for bonifications</span>} description="Conecta tus herramientas y centraliza la información más importante de ellas. Conexiones con Agentes IA, formularios, finanzas, email, marketing, comunicación..." /> 
                                 </div>
                              </div>
                           </div> */}


                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoExpertArea;